<template>
  <div class="background">
    <div
        style="background-color: rgba(0,0,0,0.82); min-height: 88vh; min-width: 100vw">
      <v-lazy transition="scale-transition">
        <div class="home">

          <div class="containerOne hidden-md-and-down desktop ">
            <v-row class="ml-0s my-0" style="height: 750px;width: 100%">
              <v-col cols="0" lg="2" md="1" sm="1"></v-col>
              <v-col cols="4" lg="4" md="5" sm="5">
                <h1 class="text-white fade-in-two überschrift"
                    style=" font-size: 40px;margin-right: 9%; margin-top: 125px">Dualer Student in Informatik und
                  Software-Entwicklung
                </h1>
                <p class="text-white fade-in-three" style="font-size: 20px;margin-right: 30%; margin-top: 5%">
                  Student im {{ currentSemester }}. Semester am IT-Center in Dortmund. In Kooperation mit dem
                  Partnerunternehmen QuinScape
                  GmbH</p>
                <div class="d-flex justify-start" style="width: 100%">
                  <v-btn class="button fade-in-four"
                         style="margin-top: 13%;"
                         @click="scrollDown">
                    <b>Learn more about me</b>
                  </v-btn>
                </div>
              </v-col>
              <v-col class="d-flex justify-center mt-16" cols="3" lg="4" md="6" sm="6">
                <v-img class="image fade-in-one" src="../assets/foto.png" style="width: auto; height: 500px"
                       transition="slide-y-transition"/>
              </v-col>
              <v-col cols="0" lg="3" md="0" sm="0"></v-col>
            </v-row>

          </div>

          <div class="containerTwo hidden-md-and-down fade-in-four desktop mt-10">
            <v-row class="d-flex justify-center mt-10" style="height: 50%; width: 100vw;">
              <v-col class="card cardOne d-flex align-center" cols="4" lg="3" md="4" sm="4"
                     xs="12">

                <v-row class="d-flex justify-center" style="width: 100%">
                  <v-col cols="10">
                    <h2 class="text-center">Kontaktdaten</h2>
                  </v-col>
                  <v-col class="align-center d-flex linkCol" cols="8">
                    <Icon class="iconDaten" icon="material-symbols:work-sharp"/>
                    <a class="ml-3 link" href="http://www.QuinScape.de" target="_blank">QuinScape GmbH</a>
                  </v-col>
                  <v-col class="align-center d-flex linkCol" cols="8">
                    <Icon class="iconDaten" icon="ic:baseline-school"/>
                    <a class="ml-3 link" href="https://www.itc-dortmund.de/" target="_blank">IT-Center Dortmund</a>
                  </v-col>
                  <v-col class="align-center d-flex linkCol" cols="8">
                    <Icon class="iconDaten" icon="ic:round-email"/>
                    <a class="ml-3 link" href="mailto:leandro.graf@quinscape.de">leandro.graf@
                      <br>quinscape.de</a>
                  </v-col>
                  <v-col class="align-center d-flex linkCol" cols="8">
                    <Icon class="iconDaten" icon="ic:round-email"/>
                    <a class="ml-3 link"
                       href="mailto:leandro.graf@itc-studenten.de">leandro.graf@
                      <br>itc-studenten.de</a>
                  </v-col>
                </v-row>

              </v-col>

              <v-col class="card align-center" cols="3" lg="4" md="4" sm="4">
                <div class="justify-center d-flex">
                  <Icon icon="eos-icons:rotating-gear" style="font-size: 200px"/>
                </div>
                <div class="pt-10 d-flex justify-center">
                  <button class="glow-on-hover " type="button" @click="$router.push('/projekte')">Meine Projekte
                  </button>
                </div>
              </v-col>

              <v-col class="cardThree card d-flex align-center" cols="4" lg="3" md="4" sm="4">
                <v-row class="d-flex justify-center align-center" style="width: 100%">
                  <v-col class="ContainerTwoCardOneColInhalt" cols="12">
                    <h2> Anmelden</h2>
                  </v-col>
                  <v-col class="ContainerTwoCardOneColInhalt" cols="9">
                    <p class="text-center ">
                      Sie möchten meine Cloud nutzen und haben Anmeldedaten von mir bekommen? Dann melden Sie sich
                      hier mit ihren Anmeldedaten an. Wenn Sie noch keine bekommen haben und meine Cloud nutzen
                      möchten,
                      dann melden Sie sich gerne per Mail bei mir.
                    </p>
                  </v-col>
                  <!--              <v-col class="ContainerTwoCardOneColInhalt icon" cols="12">
                                  <Icon icon="ic:baseline-log-in"/>
                                </v-col>-->
                  <v-col v-if="!user" class="ContainerTwoCardOneColInhalt" cols="12">
                    <v-btn class="button"
                           @click="loginDialog=true">
                      Anmelden
                    </v-btn>
                  </v-col>
                  <v-col v-if="user" class="d-flex justify-center" cols="7">
                    <v-btn class="text-white button" style="background-color: black"
                           @click="$router.push('/files')">
                      Dateien
                    </v-btn>
                  </v-col>
                  <v-col v-if="user" class="d-flex justify-center" cols="7">
                    <v-btn class="text-white button" style="background-color: black" @click="logout">
                      Ausloggen
                    </v-btn>
                  </v-col>
                  <v-col v-if="user1 === 'Admin'" class="d-flex justify-center" cols="7">
                    <v-btn class="text-white button" style="background-color: black"
                           @click="$router.push('/verwaltung')">
                      Verwaltung
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>

          <div ref="scrollTarget"
               class="containerThree hidden-md-and-down fade-in-four desktop mt-10 ml-0"
               style="width: 100vw; padding: 50px">
            <v-row class="d-flex justify-center aboutMeIcon" style="width: 100%;">
              <v-col class="text-center" cols="10">
                <Icon class="mt-5" icon="line-md:account"
                      style="font-size: 225px"/>
              </v-col>
              <v-col class="mt-n10" cols="10">
                <h1 class="text-white text-center">About Me</h1>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center aboutMeIcon" style="width: 100%;">
              <v-col class="text-center text-white" cols="10" lg="6" md="8" sm="10">
                Ich bin Leandro Graf, {{calculateAge()}} Jahre alt und habe mein Fachabitur erfolgreich
                abgeschlossen.
                Ich habe ein Jahr lang Bundesfreiwilligendienst an einer Grundschule mit Schulbegleitung geleistet, was
                mir
                wertvolle Erfahrungen im Bildungsbereich gebracht hat.

                Aktuell befinde ich mich im zweiten Semester meines Dualen Studiums am IT-Center in Dortmund, in
                Zusammenarbeit mit meinem Partnerunternehmen QuinScape. Meine Leidenschaft gilt dem Programmieren, und
                ich
                bin begeistert von den vielfältigen Möglichkeiten, die die IT-Branche bietet.

                Während meines Studiums habe ich bereits mein Wissen und meine Fähigkeiten in verschiedenen
                Programmiersprachen und IT-Konzepten erweitert. Ich habe auch bereits einige Projekte erfolgreich
                abgeschlossen, darunter ein Projekt für meinen Professor am IT-Center im Fachbereich BWL. Diese Projekte
                haben mir wertvolle praktische Erfahrungen verschafft und meine Fähigkeiten in der Umsetzung von
                IT-Lösungen
                gestärkt.

                Es erfüllt mich mit Stolz, meine Leidenschaft für das Programmieren in mein Studium und meine berufliche
                Laufbahn einzubringen. Ich bin bestrebt, meine Kenntnisse und Fähigkeiten kontinuierlich
                weiterzuentwickeln
                und mich in der IT-Branche weiterzuentfalten.

                Ich freue mich auf weitere spannende Projekte und Herausforderungen, die mir die Möglichkeit bieten,
                mein
                Wissen und meine Fähigkeiten weiter auszubauen und meine Ziele in der IT-Branche zu verwirklichen.
              </v-col>
            </v-row>
          </div>


          <div class="containerOne tablet hidden-lg-and-up">
            <v-row class="mt-3 ml-0 d-flex justify-center" style="width: 100vw">
              <v-col class="d-flex justify-center mt-5" cols="7" style="height: 50vh">
                <v-img class="d-flex justify-center" src="../assets/foto.png"
                       transition="slide-y-transition"/>
              </v-col>
              <v-col class="mt-3" cols="12">
                <h1 class="text-center text-white mt-n5"
                    style="font-size: 40px; margin-left: 10%;margin-right: 10%">
                  Dualer Student in Informatik und Software-Entwicklung
                </h1>
              </v-col>
              <v-col cols="12">
                <p class="text-center text-white mt-5"
                   style="font-size: 20px; margin-left: 10%;margin-right: 10%">
                  Student im {{ currentSemester }}. Semester am IT-Center in Dortmund. In Kooperation mit dem
                  Partnerunternehmen QuinScape
                  GmbH
                </p>
                <div class="d-flex justify-center">
                  <v-btn class="text-white" style="background-color: black; margin-top: 5%"
                         variant="outlined"
                         @click="$router.push('#about')">
                    <b>Learn more about me</b>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </div>

          <div class="containerTwo tablet hidden-lg-and-up mt-10">


            <v-row class="d-flex justify-center pl-3 mx-0" style="width: 100%; background-color: rgba(33,129,141,0.71)">

              <v-col class=" align-center" cols="12">
                <div class="justify-center d-flex">
                  <Icon icon="eos-icons:rotating-gear" style="font-size: 200px"/>
                </div>
                <div class="pt-10 d-flex justify-center">
                  <button class="glow-on-hover " type="button" @click="$router.push('/projekte')">Meine Projekte
                  </button>
                </div>
              </v-col>

              <v-divider class="mt-10" thickness="5"/>

              <v-col class="d-flex align-center mt-5" cols="12">
                <v-row class="d-flex justify-center align-center" style="width: 100%">
                  <v-col class="ContainerTwoCardOneColInhalt" cols="12">
                    <h2> Anmelden</h2>
                  </v-col>
                  <v-col class="ContainerTwoCardOneColInhalt" cols="9">
                    <p class="text-center ">
                      Sie möchten meine Cloud nutzen und haben Anmeldedaten von mir bekommen? Dann melden Sie sich
                      hier mit ihren Anmeldedaten an. Wenn Sie noch keine bekommen haben und meine Cloud nutzen
                      möchten,
                      dann melden Sie sich gerne per Mail bei mir.
                    </p>
                  </v-col>
                  <!--              <v-col class="ContainerTwoCardOneColInhalt icon" cols="12">
                                  <Icon icon="ic:baseline-log-in"/>
                                </v-col>-->
                  <v-col v-if="!user" class="ContainerTwoCardOneColInhalt" cols="12">
                    <v-btn class="button"
                           @click="loginDialog=true">
                      Anmelden
                    </v-btn>
                  </v-col>
                  <v-col v-if="user" class="d-flex justify-center" cols="7">
                    <v-btn class="text-white button" style="background-color: black"
                           @click="$router.push('/files')">
                      Dateien
                    </v-btn>
                  </v-col>
                  <v-col v-if="user" class="d-flex justify-center" cols="7">
                    <v-btn class="text-white button" style="background-color: black" @click="logout">
                      Ausloggen
                    </v-btn>
                  </v-col>
                  <v-col v-if="user1 === 'Admin'" class="d-flex justify-center " cols="7">
                    <v-btn class="text-white button hidden-sm-and-down" style="background-color: black"
                           @click="$router.push('/verwaltung')">
                      Verwaltung
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>

              <v-divider thickness="5"/>

              <v-col class="mt-5" cols="12">
                <v-row class="d-flex justify-center ml-0" style="width: 100%">
                  <v-col cols="10">
                    <h2 class="text-center">Kontaktdaten</h2>
                  </v-col>
                  <v-col class="align-center justify-center d-flex linkCol" cols="8">
                    <Icon class="iconDaten" icon="material-symbols:work-sharp"/>
                    <a class="ml-3 link" href="http://www.QuinScape.de">QuinScape GmbH</a>
                  </v-col>
                  <v-col class="align-center justify-center d-flex linkCol" cols="8">
                    <Icon class="iconDaten" icon="ic:baseline-school"/>
                    <a class="ml-3 link" href="https://www.itc-dortmund.de/">IT-Center Dortmund</a>
                  </v-col>
                  <v-col class="align-center justify-center d-flex linkCol" cols="8">
                    <Icon class="iconDaten" icon="ic:round-email"/>
                    <a class="ml-3 link" href="mailto:leandro.graf@quinscape.de">leandro.graf@
                      <br>quinscape.de</a>
                  </v-col>
                  <v-col class="align-center justify-center d-flex linkCol" cols="8">
                    <Icon class="iconDaten" icon="ic:round-email"/>
                    <a class="ml-3 link"
                       href="mailto:leandro.graf@itc-studenten.de">leandro.graf@
                      <br>itc-studenten.de</a>
                  </v-col>
                </v-row>
              </v-col>


            </v-row>
          </div>
          <div id="about" ref="scrollTarget" class="containerThree hidden-lg-and-up fade-in-four desktop mt-10 ml-0"
               style="width: 100vw; padding: 50px">
            <v-row class="text-center justify-center mx-0" style="width: 100%; color: #21818d">
              <v-col class="justify-center d-flex" cols="12">
                <Icon class="mt-5 justify-center d-flex" icon="line-md:account"
                      style="font-size: 225px"/>
              </v-col>
              <v-col class="mt-n10" cols="12">
                <h1 class="text-white text-center">About Me</h1>
              </v-col>
              <v-col class="d-flex justify-center" cols="12" md="8" sm="8">
                <p class="d-flex justify-center text-center text-white">
                  Ich bin Leandro Graf, {{calculateAge()}} Jahre alt, habe mein Fachabitur abgeschlossen und ein Jahr
                  Bundesfreiwilligendienst geleistet. Aktuell studiere ich Dual am IT-Center in Dortmund und arbeite mit
                  QuinScape zusammen. Ich liebe das Programmieren und habe bereits praktische Erfahrungen gesammelt.
                  Mein
                  Ziel ist es, mich in der IT-Branche weiterzuentwickeln und neue Projekte anzunehmen.
                </p>
              </v-col>
            </v-row>
          </div>

        </div>
      </v-lazy>
    </div>
  </div>
  <template class="login">
    <v-row justify="center">
      <v-dialog
          v-model="loginDialog"
          persistent
          width="512"
      >
        <v-form @submit.prevent="login">
          <v-card color="black" style="box-shadow: 0px 0px 50px 30px black">
            <v-card-title class="text-center">
              <span class="text-h5">Anmelden</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        v-model="email"
                        label="Nutzername"
                        required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        v-model="password"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPassword ? 'text' : 'password'"
                        label="Passwort"
                        required
                        @click:append="showPassword = !showPassword"
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="error !== ''" cols="12">
                    <v-alert v-if="error == 'AxiosError: Request failed with status code 401'" color="red">Der
                      Nutzername oder das Passwort ist falsch!
                    </v-alert>
                    <v-alert v-else class="text-center" color="red">Es bestehen
                      derzeit
                      Serverprobleme.
                      Bitte wenden Sie sich an den Administrator der Website, um das Problem zu beheben!
                    </v-alert>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
              <v-btn
                  v-if="(!loadAnzeige && (password == '' || email == '') || (password == '' && email == '') && error == '') || ((password == currentPassword && email == currentEmail) && error !== '')"
                  class="buttonClose"
                  style="color: red !important;"
                  variant="text"

                  @click="loginDialog = false; error = ''; email= ''; password = ''"
              >
                Close
              </v-btn>
              <v-btn
                  v-if="(!loadAnzeige && (password !== '' && email !== '' && error == '') || (!loadAnzeige && ((password !== currentPassword || email !== currentEmail) && error !== '') && (password !== '' && email !== ''))) && !loadAnzeige"
                  class="buttonLogin"
                  type="submit"
                  variant="text"
                  @click="login"
              >
                Login
              </v-btn>
              <Icon v-if="loadAnzeige" icon="line-md:loading-twotone-loop" style="font-size: 40px"/>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-row>
  </template>

  <v-snackbar
      v-model="wartungsarbeitenBanner"
      color="red"
      location="top"
      timeout="5000"
  >
    Es bestehen zur Zeit Wartungsarbeiten. Bei Problemen bitten ich um Ihr Verständnis.
  </v-snackbar>

</template>

<script>
import {Icon} from '@iconify/vue';
import {mapGetters} from "vuex";
import axios from "axios";

export default {
  computed: {
    ...mapGetters(['user']),
  },
  data: () => ({
    wartungsarbeitenBanner: false,

    password: '',
    email: '',
    ordnerpfad: '',
    regist: false,
    loginDialog: false,
    loadAnzeige: false,
    model: null,
    status: '',
    user1: '',
    rechte: ['Admin', 'Nutzer'],
    ordner: [],
    erstellterOrdner: '',
    currentIndex: 0, // Aktueller Index des sichtbaren Slides
    showPassword: false,
    error: '',
    currentEmail: '',
    currentPassword: '',

    startDate: new Date(2022, 9, 1),
    currentSemester: 1,

  }),
  name: 'HomeView',
  components: {
    Icon
  },
  mounted() {
    this.calculateCurrentSemester();
    this.getUser()
    this.$store.state.themeColor = '#21818d'
  },
  methods: {
    calculateAge() {
      const birthdate = new Date('2003-09-19');
      const currentDate = new Date();

      const age = currentDate.getFullYear() - birthdate.getFullYear();

      // Überprüfen, ob der Geburtstag in diesem Jahr bereits stattgefunden hat
      const birthMonth = birthdate.getMonth();
      const currentMonth = currentDate.getMonth();
      if (currentMonth < birthMonth || (currentMonth === birthMonth && currentDate.getDate() < birthdate.getDate())) {
        return age - 1;
      }

      return age;
    },

    async getUser() {
      const response = await axios.get('https://leandro-graf.de:8080/auth/user');
      await this.$store.dispatch('user', response.data);
    },

    calculateCurrentSemester() {
      const currentDate = new Date();

      const date = currentDate.getFullYear() - this.startDate.getFullYear()
      if (currentDate.getMonth() < 4) {
        this.currentSemester = date * 2 - 1
      } else if (currentDate.getMonth() >= 4 && currentDate.getMonth() < 9) {
        this.currentSemester = date * 2
      } else if (currentDate.getMonth() >= 9) {
        this.currentSemester = date * 2 + 1
      }
    },

    scrollDown() {
      window.scrollTo({top: 2000, behavior: 'smooth'});
    },

    async getOrdner() {
      try {
        const response = await axios.get("https://leandro-graf.de:8080/auth/ordner", {});
        const ordnerListe = response.data;

        ordnerListe.forEach((ordner) => {
          if (ordner !== '.DS_Store') {
            this.ordner.push(ordner);
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    logout() {
      localStorage.removeItem('token');
      this.$store.dispatch('user', null);
      location.reload();
    },
    async login() {
      try {
        this.currentPassword = this.password
        this.currentEmail = this.email
        this.loadAnzeige = true;
        const response = await axios.post('https://leandro-graf.de:8080/auth/login', {
          email: this.email,
          password: this.password
        });
        localStorage.setItem('token', response.data)
        await this.$store.dispatch('user', response.data.user)
        this.loadAnzeige = false;

        await this.$router.push('/files');
        await location.reload()

      } catch (e) {
        this.loadAnzeige = false;
        this.error = e;
        console.log(e)
      }
    },


  },

}
</script>

<style scoped>

.überschrift {
  transition: 1s;
  font-size: 42px !important;
}

.überschrift:hover {
  transition: 0.5s;
  font-size: 45px !important;
}

.buttonLogin {
  color: greenyellow;
  background-color: black;
  transition: 1.5s;
}

.buttonLogin:hover {
  color: black;
  transition: 0.5s;
  background-color: white !important;
}

.buttonClose {
  color: red;
  background-color: black;
  transition: 1.5s;
}

.buttonClose:hover {
  color: black !important;
  transition: 0.5s;
  background-color: white !important;
}

.containerThree {
  overflow: visible;
}

.cardOne {
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;
}

.cardThree {
  border-bottom-right-radius: 100px;
  border-top-right-radius: 100px;
}

.card {
  /*background-color: #969ea9;*/
  background-color: #666666;
}

.ContainerTwoCardOneColInhalt {
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aboutMeIcon {
  color: #21818d;
  overflow: hidden;
  transform: scale(1.0);
  transition: transform 0.5s;
}

.aboutMeIcon:hover {
  transform: scale(1.1);
  transition: transform 0.5s;
}

.image {
  overflow: hidden;
  display: grid;
  transform: scale(0.9);
  transition: transform 0.5s;
}

.image:hover {
  transform: scale(1.0);
  transition: transform 0.5s;
}

.iconDaten {
  font-size: 40px;
}

.linkCol:hover {
  color: blue !important;
  transition: 0.5s;
}

.linkCol {
  transition: 1.5s;
}

.link {
  text-decoration: none;
  color: #023338;
  transition: 1.5s;
}

.link:hover {
  color: blue;
  transition: 0.5s;
}

.glow-on-hover {
  width: 220px;
  height: 50px;
  border: none;
  outline: none;
  color: #fff !important;
  transition: 2s;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}

.glow-on-hover:hover {
  color: black !important;
  transition: 1s;
}

.glow-on-hover:before,
.glow-on-hover:after {
  content: '';
  position: absolute;
  z-index: -1;
  border-radius: 10px;
}

.glow-on-hover:before {
  background: linear-gradient(45deg, #ff5f6d, #ffc371, #fffc00, #00dbde, #8183d3, #ff00c8);
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  filter: blur(5px);
  background-size: 400%;
  transition: opacity .3s ease-in-out;
  animation: glowing 20s linear infinite;
}

.glow-on-hover:after {
  background: #111;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 1.5s;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:hover:after {
  transition: 0.5s;
  background-color: transparent;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.button {
  color: white !important;
  background-color: black;
  transition: 1.5s;
}

.button:hover {
  color: black !important;
  transition: 0.5s;
  background-color: white !important;
}

.animation > div > div {
  padding: 0.25rem 0.75rem;
  height: 2.81rem;
  margin-bottom: 2.81rem;
  display: inline-block;
}

.animation div:first-child {
  animation: text-animation 8s infinite;
}

.first div {
  background-color: #20a7d8;
}

.second div {
  background-color: #CD921E;
}

.third div {
  background-color: #c10528;
}

@keyframes text-animation {
  0% {
    margin-top: 0;
  }
  10% {
    margin-top: 0;
  }
  20% {
    margin-top: -5.62rem;
  }
  30% {
    margin-top: -5.62rem;
  }
  40% {
    margin-top: -11.24rem;
  }
  60% {
    margin-top: -11.24rem;
  }
  70% {
    margin-top: -5.62rem;
  }
  80% {
    margin-top: -5.62rem;
  }
  90% {
    margin-top: 0;
  }
  100% {
    margin-top: 0;
  }
}

@keyframes typing {
  0% {
    width: 0;
  }
  50% {
    width: 100%;
  }
  100% {
    width: 100%;
  }
}

.fade-in-one {
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out forwards;
}

.fade-in-two {
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
}

.fade-in-three {
  opacity: 0;
  animation: fadeIn 1.5s ease-in-out forwards;
}

.fade-in-four {
  opacity: 0;
  animation: fadeIn 2s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.background {
  background-image: url('../assets/home.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

</style>
