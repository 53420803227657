<template>
  <div class="hintergrund">
    <div class="d-flex align-center justify-center" style="min-height: 88vh;width: 100vw;">
      <v-btn class="pageButton" @click="$router.push('/')">Startseite</v-btn>
    </div>
  </div>
</template>

<script>

export default {
  name: "PageNotFoundView",
  components: {},
  mounted() {
    this.$store.state.themeColor = '#21818d'
  }
}
</script>

<style scoped>
.hintergrund {
  background-image: url('../assets/PageNotFound.jpg');
  background-size: cover;
  background-position: center;
  height: 86vh;
  overflow: hidden;

}

.pageButton {
  margin-top: 25%;
  border: 2px solid red;
  background-color: transparent;
  color: white;
  transition: 0.5s;
}

.pageButton:hover {
  font-size: 16px;
  transition: 0.5s;
}
</style>