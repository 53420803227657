<template>
  <div>
    <v-app id="app" style="min-height: 80vh">
      <div id="home" class="d-flex flex-column fill-height justify-center align-center">
        <HeaderComponent/>
        <div :style="{ backgroundColor: themeColor }" style=" min-height: 90vh; min-width: 100vw;">
          <v-main>
            <router-view/>
          </v-main>
        </div>
      </div>
      <FooterComponent/>
    </v-app>
  </div>
</template>

<script>
import axios from "axios";
import HeaderComponent from "@/components/HeaderComponent";
import FooterComponent from "@/components/FooterComponent";
import {mapGetters} from "vuex";

export default {
  components: {
    HeaderComponent,
    FooterComponent,
  },
  computed: {
    ...mapGetters(['themeColor'])
  },

  async created() {
    const response = await axios.get('https://leandro-graf.de:8080/auth/user');
    await this.$store.dispatch('user', response.data);
  },
  name: 'App',

  data: () => ({
    //
  })
}
</script>

<style>
::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
</style>
