<template>
  <h1 class="DesktopAnsicht hidden-sm-and-down text-center überschrift mt-5" style="font-size: 50px; height: 30px">
    MyWatchlist</h1>
  <h1 class="tabletAnsicht hidden-md-and-up text-center überschrift mt-5" style="font-size: 30px; height: 20px">
    MyWatchlist</h1>
  <v-row class="d-flex justify-center mt-5 ml-0 mx-0 my-0 ma-0" style="width: 100%;">
    <v-col class="hidden-xs d-flex align-center" cols="8" lg="6" md="9" sm="10">
      <v-carousel :interval="4000"
                  class="hidden-xs"
                  cycle
                  height="400"
                  hide-delimiter-background
                  hide-delimiters
                  show-arrows="hover"
      >
        <v-carousel-item
            v-for="bild in bilder"
            :key="bild"
            :src="bild.img"
            @click="weiterleiten(bild.path)"
        ></v-carousel-item>
      </v-carousel>
    </v-col>
    <v-col class="d-flex align-center" cols="12" lg="6" md="9" sm="10">
      <v-expansion-panels v-model="panel">
        <v-expansion-panel
            v-for="text in texte"
            :key="text"
            class="text-black"
            style="background-color: aqua">
          <v-expansion-panel-title>
            <h4>{{ text.titel }}</h4>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <p class="mx-2">{{ text.inhalt }}</p>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <v-col class="d-flex justify-center mt-5 pt-10" cols="12">
      <v-btn class="button" @click="weiterleiten('/MyWatchlist')">
        <p style="font-max-size: 20px !important;"><b>Besuchen Sie MyWatchlist</b></p>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: "MyWatchlistComponent",
  data: () => ({
    panel: [0],
    onboarding: 0,
    length: '',

    bilder: [
      {
        img: require('../../assets/ProjektBilder/MyWatchlistHome.jpg'),
        path: '/MyWatchlist'
      },
      {
        img: require('../../assets/ProjektBilder/MyWatchlistFilme.png'),
        path: '/MyWatchlist/liste'
      }
    ],

    texte: [
      {
        titel: 'Vorstellung der Webanwendung',
        inhalt: 'Als Teil meines ersten Semesters im IT-Studium habe ich an einem spannenden Projekt gearbeitet: MyWatchlist. Bei dieser Webanwendung handelt es sich um eine personalisierte Filmliste, auf der Benutzer Filme hinzufügen, markieren, bewerten und kommentieren können. Mein Ziel war es, eine benutzerfreundliche Plattform zu entwickeln, die es den Nutzern ermöglicht, ihre eigenen Filmerfahrungen zu dokumentieren und zu organisieren.'
      },
      {
        titel: 'Anmeldung und individuelle Konten',
        inhalt: 'MyWatchlist ermöglicht es den Benutzern, sich mit einem eigenen Konto anzumelden. Jeder Nutzer kann seine persönliche Filmliste erstellen und verwalten, die von anderen Nutzern nicht eingesehen werden kann. Die individuellen Konten bieten eine private Umgebung, in der Benutzer ihre Filmpräferenzen festhalten und verfolgen können.'
      },
      {
        titel: 'Erstellen von Filmkarten und Verwaltung',
        inhalt: 'Die Hauptfunktion von MyWatchlist besteht darin, dass Benutzer Filmkarten erstellen können. Eine Filmkarte enthält Informationen zum Film wie Titel, Titelbild, Erscheinungsjahr, Bewertung und Kommentar. Benutzer können neue Filme zur Liste hinzufügen, indem sie die relevanten Details eingeben. Diese Funktion erleichtert es den Nutzern, ihre Filmauswahl zu organisieren und den Überblick zu behalten.'
      },
      {
        titel: 'Markieren von Filmen und Bewertungsfunktion',
        inhalt: 'Um den Fortschritt bei der Sichtung von Filmen zu verfolgen, können Benutzer ihre Filmkarten als "gesehen" oder "nicht gesehen" markieren. Diese Funktion hilft den Nutzern dabei, festzustellen, welche Filme sie bereits gesehen haben und welche noch auf ihrer Watchlist stehen. Zusätzlich können Benutzer jedem Film eine persönliche Bewertung zuweisen, um ihre Meinung und Bewertung des Films festzuhalten.'
      },
      {
        titel: 'Kommentarfunktion und persönliche Eindrücke',
        inhalt: 'MyWatchlist ermöglicht es den Benutzern, zu jedem Film Kommentare hinzuzufügen. Hier können sie ihre persönlichen Eindrücke, Rezensionen oder Notizen zu einem bestimmten Film festhalten. Diese Kommentare dienen als persönliches Tagebuch und ermöglichen es den Nutzern, ihre Gedanken und Empfindungen zu teilen und zu reflektieren.'
      }
    ]

  }),
  beforeUnmount() {
    window.removeEventListener('resize', this.checkMobileView); // Event-Listener entfernen, um Speicherlecks zu vermeiden
  },
  mounted() {
    this.length = this.bilder.length
    this.checkMobileView();
    window.addEventListener('resize', this.checkMobileView);
  },
  methods: {
    checkMobileView() {
      if (window.innerWidth <= 768) {
        this.panel = '';
      } else {
        this.panel = [0];
      }
    },
    weiterleiten(path) {
      window.location.assign('https://leandro-graf.de' + path)
    },
  }
}
</script>

<style scoped>
.button {
  color: black !important;
  background-color: aqua;
}

.button:hover {
  color: white !important;
  background-color: black !important;
  font-size: 15px;
}

.v-expansion-panel {
  max-width: 700px;
}

.überschrift {
  color: aqua;
}
</style>