<template>
  <h1 class="DesktopAnsicht hidden-sm-and-down text-center überschrift mt-5" style="font-size: 50px; height: 30px">
    Bürgergemeinschaft Werl</h1>
  <h1 class="tabletAnsicht hidden-md-and-up text-center überschrift mt-5" style="font-size: 30px; height: 20px">
    BG Werl</h1>
  <v-row class="d-flex justify-center mt-5 ml-0 mx-0 my-0 ma-0" style="width: 100%;">
    <v-col class="hidden-xs d-flex align-center" cols="8" lg="6" md="9" sm="10">
      <v-carousel :interval="4000"
                  class="hidden-xs"
                  cycle
                  height="400"
                  hide-delimiter-background
                  hide-delimiters
                  show-arrows="hover"
      >
        <v-carousel-item
            v-for="bild in bilder"
            :key="bild"
            :src="bild.img"
            @click="weiterleiten(bild.url)"
        ></v-carousel-item>
      </v-carousel>
    </v-col>
    <v-col class="d-flex justify-center align-center" cols="12" lg="6" md="9" sm="10" >
      <v-expansion-panels v-model="panel">
        <v-expansion-panel
            v-for="text in texte"
            :key="text"
            class="text-white">
          <v-expansion-panel-title>
            <h3>{{ text.titel }}</h3>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <p class="mx-2">{{ text.inhalt }}</p>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <v-col class="d-flex justify-center mt-5 pt-10" cols="12">
      <v-btn class="button" @click="weiterleiten('https://www.bg-werl.de')">
        <p style="font-max-size: 20px !important;"><b>Besuchen Sie die BG-Werl</b></p>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: "BG-WerlComponent",
  data: () => ({
    panel: [0],
    onboarding: 0,
    length: '',

    bilder: [
      {
        img: require('../../assets/ProjektBilder/BGWerlHome.png'),
        url: 'https://bg-werl.de'
      },
      {
        img: require('../../assets/ProjektBilder/BGWerlAktuelles.png'),
        url: 'https://bg-werl.de/aktuelles'
      },
      {
        img: require('../../assets/ProjektBilder/BGWerlErfolge.png'),
        url: 'https://bg-werl.de/erfolge'
      },
      {
        img: require('../../assets/ProjektBilder/BGWerlMitgliedWerden.png'),
        url: 'https://bg-werl.de/mitglied'
      },
      {
        img: require('../../assets/ProjektBilder/BGWerlVerwaltung.png'),
        url: 'https://bg-werl.de/'
      }
    ],

    texte: [
      {
        titel: 'Modernisierung der BG-Werl Website',
        inhalt: 'Im Rahmen meines dritten Semesters als IT-Student hatte ich das spannende Privileg, gemeinsam mit meinem Kommilitonen Stefan Franke an einem aufregenden Projekt für die Bürgergemeinschaft Werl (BG-Werl) zu arbeiten. Unser Auftrag bestand darin, die Webseite der BG-Werl zu erneuern und modernisieren. Dabei lag unser Fokus auf der Implementierung einer effizienten Verwaltungsstruktur, die es ermöglicht, Beiträge, Benutzer, Erfolge und Kommentare optimal zu organisieren.'
      },
      {
        titel: 'Die Verwaltung hinter BG-Werl',
        inhalt: 'Unsere Überarbeitung der BG-Werl Webseite umfasste eine umfassende Verwaltungsfunktion. Diese ermöglicht es den Administratoren, sämtliche aktuellen Beiträge, Erfolge, Mitgliederinformationen, das Pressearchiv, Kommentare und Benutzerprofile übersichtlich zu verwalten. Die Verwaltungsoberfläche stellt sicher, dass relevante Informationen stets aktuell sind und leicht zugänglich bleiben. Beiträge werden effizient erfasst, kategorisiert und veröffentlicht, um die Kommunikation mit der Gemeinschaft zu erleichtern. Erfolge können präsentiert und gewürdigt werden, während das Mitgliederverzeichnis alle wichtigen Informationen über die Mitglieder bereithält. Das Pressearchiv ermöglicht einen Blick in die Vergangenheit der BG-Werl und ihre mediale Präsenz. Kommentare werden moderiert und organisiert, um eine konstruktive Diskussionsumgebung zu gewährleisten. Die Benutzerverwaltung ermöglicht es, die Interaktion auf der Webseite sicher zu steuern.'
      },
      {
        titel: 'Mitglied werden der BG Werl',
        inhalt: 'Die BG-Werl Webseite bietet einen klaren und unkomplizierten Weg, um Mitglied zu werden. Interessierte können sich online bewerben. Die Verwaltung hat die Möglichkeit, die eingegangenen Mitgliedsanträge zu überprüfen, zu bearbeiten und bei Bedarf mit den Bewerbern in Kontakt zu treten. Sobald alles geregelt ist, kann der Antrag als "gesehen" markiert oder entfernt werden, je nachdem, ob die Mitgliedschaft akzeptiert wurde oder nicht. Dieser Prozess vereinfacht die Mitgliedschaftsverwaltung und sorgt für eine nahtlose Integration neuer Mitglieder.'
      },
      {
        titel: 'Zukünftige Erweiterung zur Diskussionsförderung',
        inhalt: 'Ein interessanter Ansatz, den wir in Erwägung ziehen, ist die mögliche Integration einer Kommentarfunktion auf der Webseite. Diese Funktion würde es den Besuchern ermöglichen, Kommentare zu verfassen, die von den Administratoren überprüft und akzeptiert oder abgelehnt werden können. Akzeptierte Kommentare würden dann auf der BG-Werl Webseite angezeigt werden, um die Interaktion und Diskussion zu fördern. Es ist jedoch wichtig anzumerken, dass diese Funktion derzeit noch in der Planungsphase ist und noch nicht umgesetzt wurde. Mit unserer Arbeit an der BG-Werl Webseite streben wir danach, eine moderne Plattform zu schaffen, die die Transparenz, Effizienz und Interaktion der Bürgergemeinschaft unterstützt. Unsere Bemühungen konzentrieren sich darauf, die Webseite zu einem Ort zu machen, an dem Mitglieder und Besucher gleichermaßen Informationen finden, sich austauschen und aktiv beteiligen können.'
      }
    ],


  }),
  beforeUnmount() {
    window.removeEventListener('resize', this.checkMobileView); // Event-Listener entfernen, um Speicherlecks zu vermeiden
  },
  mounted() {
    this.length = this.bilder.length
    this.checkMobileView();
    window.addEventListener('resize', this.checkMobileView);
  },
  methods: {
    checkMobileView() {
      if (window.innerWidth <= 768) {
        this.panel = '';
      } else {
        this.panel = [0];
      }
    },
    weiterleiten(url) {
      window.open(url, '_blank')
    },
  }
}
</script>

<style scoped>
.button {
  background-color: #2F53A7;
}

.button:hover {
  color: white !important;
  background-color: black !important;
  font-size: 15px;
}

.v-expansion-panel {
  background-color: #2F53A7;
  max-width: 700px;
}

p {
  font-size: 16px;
}

.überschrift {
  color: white;
  text-shadow: 5px 2px #2F53A7;
}
</style>