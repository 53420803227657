<template>
  <h1 class="DesktopAnsicht hidden-sm-and-down text-center überschrift mt-5" style="font-size: 50px; height: 30px">
    Portfolio- und
    Verwaltungswebsite</h1>
  <h1 class="tabletAnsicht hidden-md-and-up text-center überschrift pt-8" style="font-size: 40px; height: 160px">
    Portfolio <br> <br> und <br> <br>Verwaltungs- <br> <br>website</h1>
  <v-row class="d-flex justify-center align-center mt-10 ml-0 mx-0 my-0 ma-0" style="width: 100%">
    <v-col class="hidden-xs" cols="8" lg="6" md="9" sm="10">
      <v-carousel :interval="4000"
                  cycle
                  height="300"
                  hide-delimiter-background
                  hide-delimiters
                  show-arrows="hover">
        <v-carousel-item
            v-for="bild in bilder"
            :key="bild"
            :src="bild.img"
            @click="weiterleiten(bild.path)"
        ></v-carousel-item>
      </v-carousel>
    </v-col>
    <v-col class="d-flex align-center" cols="12" lg="6" md="9" sm="10">
      <v-expansion-panels v-model="panel">
        <v-expansion-panel
            v-for="text in texte"
            :key="text"
            class="text-black"
            style="background-color: lightskyblue">
          <v-expansion-panel-title>
            <h4>{{ text.titel }}</h4>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <p class="mx-2">{{ text.inhalt }}</p>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <v-col class="d-flex justify-center mt-5 pt-10" cols="12">
      <v-btn class="button" href="https://brickau.de">
        <p style="font-max-size: 20px !important;"><b>Besuchen Sie Brickau.de</b></p>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: "BrickauComponent",
  data: () => ({
    panel: [0],
    onboarding: 0,
    length: '',

    bilder: [
      {
        img: require('../../assets/ProjektBilder/BrickauHome.png'),
        path: '/'
      },
      {
        img: require('../../assets/ProjektBilder/BrickauFiles.png'),
        path: '/files'
      }
    ],

    texte: [
      {
        titel: 'Vorstellung der Website',
        inhalt: 'Als Teil meines zweiten Semesters im IT-Studium habe ich gemeinsam mit meinen Kommilitonen Stefan Franke und Tim Thorben Föhrs an einer spannenden Aufgabe gearbeitet: Wir haben eine Portfolio-Website für Professor Dr. Prof. Ralf A. Brickau entwickelt. Unser Ziel war es, eine benutzerfreundliche Plattform zu schaffen, auf der der Professor nicht nur seine persönlichen Informationen präsentieren kann, sondern auch Dateien für seine Studierenden bereitstellen kann.'
      },
      {
        titel: 'Persönliche Darstellung von Professor Brickau',
        inhalt: 'Die Webseite ist in erster Linie darauf ausgerichtet, Professor Brickau und seine akademische Laufbahn vorzustellen. Unter der Rubrik "Über mich" werden seine Ausbildungen und  beruflichen Erfahrungen detailliert präsentiert. Diese Informationen vermitteln den Besuchern einen umfassenden Eindruck von Professor Brickaus Fachwissen und Expertise.'
      },
      {
        titel: 'CloudFileShare',
        inhalt: 'Eine der zentralen Funktionen unserer Cloud-Filesharing-Lösung besteht darin, dass Professor Brickau Dateien mit seinen Studierenden teilen kann. Unter der Rubrik "Dateien" können die Studierenden auf Kursunterlagen, Präsentationen und ergänzende Ressourcen zugreifen, die Professor Brickau hochgeladen hat. Dadurch wird der Informationsaustausch erleichtert und die Lernprozesse der Studierenden unterstützt.'
      },
      {
        titel: 'Rückblick auf das Projekt',
        inhalt: 'Insgesamt haben wir mit unserem ersten Projekt eine maßgeschneiderte Portfolio-Website für Professor Dr. Prof. Ralf A. Brickau entwickelt, die nicht nur seine Person präsentiert, sondern auch eine Cloud-Filesharing-Lösung für den Informationsaustausch mit seinen Studierenden bietet. Wir sind stolz auf das Ergebnis unserer Arbeit und haben wertvolle Erfahrungen in der Entwicklung benutzerfreundlicher Webanwendungen gesammelt. Mit diesem erfolgreichen Projekt im Rücken freuen wir uns darauf, in unserem IT-Studium weitere spannende Projekte in Angriff zu nehmen und unser Wissen und Können weiter auszubauen.'
      }
    ]

  }),
  mounted() {
    this.length = this.bilder.length
    this.checkMobileView();
    window.addEventListener('resize', this.checkMobileView);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkMobileView); // Event-Listener entfernen, um Speicherlecks zu vermeiden
  },
  methods: {
    checkMobileView() {
      if (window.innerWidth <= 768) {
        this.panel = '';
      } else {
        this.panel = [0];
      }
    },
    weiterleiten(path) {
      window.location.assign('https://www.brickau.de' + path)
    },
  },
}
</script>

<style scoped>
.button {
  color: black !important;
  background-color: lightskyblue;
}

.button:hover {
  color: white !important;
  background-color: black !important;
  font-size: 15px;
}

.v-expansion-panel {
  max-width: 700px;
}

.überschrift {
  color: lightskyblue;
}
</style>