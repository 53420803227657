<template>
  <v-footer absolute app class="footer" :style="{ backgroundColor: themeColor }" height="60" style="position: fixed">
    <v-row justify="center" no-gutters>
      <v-col class="text-center" cols="12">
        <b class="text" @click="$router.push('/impressum')">{{ new Date().getFullYear() }}</b>
      </v-col>
      <v-col class="text-center text-black mt-0 " cols="12" style="max-height: 100%">
        <strong class="text" @click="$router.push('/impressum')">www.leandro-graf.de</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>


import {mapGetters} from "vuex";

export default {
  name: "FooterComponent",
  components: {},
computed: {
  ...mapGetters(['themeColor'])
 }
}
</script>

<style scoped>
.footer {
  height: 60px;
  width: 100%;
  bottom: 0px;
}

.text {
  cursor: pointer;
}
</style>
