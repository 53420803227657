import {createStore} from 'vuex'


export default createStore({

    state: {
        user: false,
        themeColor: '#21818d'
    },

    getters: {
        user: (state) => {
            return state.user
        },
        password: (state) => {
            return state.password
        },
        themeColor: (state) => {
            return state.themeColor
        }
    },
    actions: {
        user(context, user) {
            context.commit('user', user)
        },
        password(context, password) {
            context.commit('password', password)

        }
    },
    mutations: {
        user(state, user) {
            state.user = user;
        },
        password(state, password) {
            state.password = password;
        }
    },

    modules: {}
})

