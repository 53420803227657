<template>
  <div class="hintergrund">
    <div class="d-flex align-center justify-center"
         style="min-height: 85vh;width: 100vw; background-color: rgba(0,0,0,0.74)">
      <div class="d-flex justify-center" style="width: 90vw;">
        <v-row class="d-flex justify-center" style="width: 100%;">
          <v-col cols="12" lg="10">
            <v-card class="desktop mt-10 mb-10"
                    style="background-color: rgba(33,129,141,0.82);min-height: 70vh;border-radius: 30px">
              <v-card-item>
                <v-card style="background-color: transparent">
                  <v-card-title class="text-center mt-2">
                    Hallo {{ user.email }}, diese Dateien stehen Ihnen zur Verfügung
                    <v-spacer></v-spacer>
                    <v-btn v-if="user" class="mt-5 button mr-2" @click="dialog=!dialog"> Upload</v-btn>
                    <v-menu v-if="this.user.status == 'Admin'" theme="dark">
                      <template v-slot:activator="{ props }">
                        <v-btn
                            class="button mt-5"
                            v-bind="props"
                        >
                          {{ selected }}
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                            v-for="(x) in ordner"
                            :key="x"
                            :value="x"
                            @click="selected  = x"
                        >
                          <v-list-item-title>{{ x }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-divider :thickness="3" class="mt-2"></v-divider>
                  </v-card-title>
                  <v-table
                      fixed-header
                      style="border-radius: 20px;background-color: transparent"
                  >
                    <tbody>
                    <tr
                        v-for="file in filesArray"
                        :key="file"
                    >
                      <td><a :href="'https://leandro-graf.de/Files/' +ausgewaehlterOrdner+ '/' + file.name"
                      >
                        {{ file.name }} </a></td>
                      <td style="width: 30px; padding-left: 0">
                        <icon class="deleteIcon" icon="line-md:close-circle" style="cursor: pointer"
                              @click="confirmDialog = true; selectedFile = file.path"></icon>
                      </td>
                      <td style="width: 30px; padding-left: 0">
                        <a :href="'https://leandro-graf.de/Files/' +ausgewaehlterOrdner+ '/' + file.name">
                          <Icon class="scopeIcon" icon="ph:file-magnifying-glass-bold"/>
                        </a>
                      </td>
                    </tr>
                    <h1 v-if="filesArray.length == 0" class="text-center mt-10">
                      Ihnen stehen zur Zeit
                      keine
                      Dateien zur
                      Verfügung</h1>
                    </tbody>
                  </v-table>
                </v-card>
              </v-card-item>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>

  <template>
    <v-row justify="center">
      <v-dialog
          v-model="confirmDialog"
          persistent
          width="auto"
      >
        <v-card color="black" style="box-shadow: 0px 0px 50px 30px black">
          <v-card-title class="text-center text-h5">
            Datei löschen
          </v-card-title>
          <v-card-text>
            Möchten Sie die Datei {{ this.selectedFile.substring(13 + user.pfad.length) }} wirklich löschen?
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn
                color="red"
                variant="text"
                @click="confirmDialog = false"
            >
              Abbrechen
            </v-btn>
            <v-btn
                color="green"
                variant="text"
                @click="confirmDialog = false, deleteDatei(selectedFile)"
            >
              Löschen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </template>


  <v-dialog
      v-model="dialog"
      persistent
      width="512">
    <v-card
        color="black"
        style="border-radius: 20px; box-shadow: 0px 0px 50px 30px black"
    >
      <h1
          class="text-center mt-2">
        Hochladen
      </h1>
      <v-divider
          :thickness="3"></v-divider>

      <v-card-item class="text-center">
        Bitte wählen Sie maximal eine Datei zum uploaden aus
      </v-card-item>
      <v-card-item class="text-center mt-n7">
        <Icon v-if="!loadAnzeige" icon="line-md:cloud-upload-loop" style="font-size: 175px"/>
        <Icon v-if="loadAnzeige" class="mt-5" icon="line-md:loading-alt-loop"
              style="font-size: 130px"/>
      </v-card-item>
      <v-card-actions>
        <v-file-input
            id="files"
            ref="files"
            v-model="uploadFile"
            chips
            class="mr-3"
            label="Bitte wählen Sie eine Datei aus"
            type="file" v-on:change="handleFilesUpload()"

        ></v-file-input>
      </v-card-actions>
      <v-card-actions v-if="error !== ''" class="pb-5 mx-7">
        <v-alert color="red">{{ error }}</v-alert>
      </v-card-actions>
      <v-card-actions class="d-flex justify-center">
        <v-btn class="button" style="background-color: black; color: red !important;"
               @click="dialog=!dialog, error = '', uploadFile = ''"> Schließen
        </v-btn>
        <v-btn
            v-if="uploadFile.length !== 0"
            class="button"
            style="background-color: black; color: lawngreen !important; text-shadow: 2px 1px 8px lawngreen"
            @click="submitFiles">
          Hochladen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>
import axios from "axios";
import {mapGetters} from "vuex";
import {Icon} from '@iconify/vue';


export default {
  data: () => ({
    selected: 'Ordner',
    ordner: [],
    allFiles: [],
    dialog: false,
    error: '',
    loadAnzeige: false,
    files: [],
    uploadFile: [],
    selectedFile: '',
    confirmDialog: false,
  }),
  created() {
    this.getOrdner()
    this.getAllData()
    /*this.umleitung()*/
    this.getAllData()
  },
  computed: {
    ...mapGetters(['user']),
    filesArray: function () {
      let result = [];
      this.allFiles.forEach((i) => {
        if (i.ordner == this.ausgewaehlterOrdner) {
          result.push(i)
        }
      })
      return result;
    },
    ausgewaehlterOrdner: function () {
      return this.selected
    }
  },
  mounted() {
    this.userÜberprüfen()
    this.setOrdner()
  },
  components: {
    Icon,
  },
  name: "FilesView",
  methods: {
    async setOrdner() {
      let i = this.user.pfad
      i = i.substring(7, i.length - 1)
      this.selected = i
    },
    userÜberprüfen: function () {
      return this.user == null
    },
    deleteDatei(pfad) {
      let löschpfad = pfad
      axios.post('https://leandro-graf.de:8080/auth/deleteFile', {message: löschpfad})
          .then(response => {
            this.getAllData()
            return response.data;
          })
          .catch(error => {
            console.error(error);
          });
    },
    handleFilesUpload() {
      this.files = this.$refs.files.files;
    },
    async umleitung() {
      await axios.get('https://leandro-graf.de/auth/user')
      if (this.user == false) {
        this.$router.push('/')
      }
    },
    async submitFiles() {
      this.ausgewaehlterOrdner = this.user.pfad.substring(7, this.user.pfad.length - 1)
      let formData = new FormData();
      this.loadAnzeige = true
      for (let i = 0; i < this.files.length; i++) {
        let file = this.files[i];
        formData.append('files', file);

      }
      await axios.post('https://leandro-graf.de:8080/auth/ordnerName', {
        message: this.ausgewaehlterOrdner
      })
      await axios.post('https://leandro-graf.de:8080/auth/upload',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
          }
      ).then(function () {
      })
          .catch(function () {
            this.error = 'Bitte wählen Sie einen Ordner aus'
          });
      await this.getAllData()
      this.uploadFile = []
      this.loadAnzeige = false
      this.dialog = false
    },
    async getAllData() {
      const response = await axios.get("https://leandro-graf.de:8080/auth/alleDateien", {});
      this.allFiles = response.data
      await this.setOrdner()
    },
    async getOrdner() {
      this.ordner = [];
      try {
        const response = await axios.get(
            "https://leandro-graf.de:8080/auth/ordner", {}
        );
        let x = response.data
        x.forEach((i) => {
          if (i != '.DS_Store') {
            this.ordner.push(i)
          }
        })
      } catch (error) {
        this.error = error
      }
    },
  }
}
</script>

<style>

a {
  display: inline;
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  text-decoration: inherit;
}


.button {
  background-color: black;
  transition: 1.5s;
  color: white !important;
}

.button:hover {
  color: black !important;
  transition: 0.5s;
  background-color: white !important;
}

.scopeIcon {
  font-size: 35px;
  color: black;
}

.scopeIcon:hover {
  font-size: 33px;
}

.mobileIcon {
  font-size: 30px;
  color: blue;
}

.dialog {

}

.deleteIcon {
  font-size: 35px;
  color: #802626;
}

.deleteIcon:hover {
  font-size: 33px;
}

.hintergrund {
  background-image: url('../assets/files.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
</style>
