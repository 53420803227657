<template>
  <div class="hintergrund">
    <div class="d-flex align-center justify-center"
         style="height: 88vh;width: 100vw; background-color: rgba(0,0,0,0.1);">
      <div class="d-flex justify-center">
        <v-card
            class="card"
            theme="dark"
            width="90vw">

          <v-tabs v-model="tab" bg-color="black">
            <v-tab class="tab" value="one">FilesverwaltungComponent</v-tab>
            <v-tab class="tab" value="two">NutzerverwaltungComponent</v-tab>
            <v-tab class="tab" value="three">OrdnerverwaltungComponent</v-tab>
          </v-tabs>
          <v-card-text>
            <v-window v-model="tab">
              <v-window-item value="one">
                <FilesverwaltungComponent></FilesverwaltungComponent>
              </v-window-item>
              <v-window-item value="two">
                <NutzerverwaltungComponent></NutzerverwaltungComponent>
              </v-window-item>
              <v-window-item value="three">
                <OrdnerverwaltungComponent></OrdnerverwaltungComponent>
              </v-window-item>
            </v-window>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<!--<template>
  <div class="hidden-sm-and-down " style="width: 100vw; background: transparent">
    <div class="subheader d-flex justify-center">
      <v-toolbar class="toolbar" style="width: 89vw; border-radius: 5px 5px 5px 5px">
        <v-toolbar-items class="d-flex justify-center" style="width: 100%">
          <v-btn
              v-for="item in menuItems"
              :key="item.title"
              style="  margin: 0px 50px 0px 50px;"
              @click="componentSwitchen(item.title)">
            <icon :icon=item.icon class="icon"></icon>
            {{ item.title }}
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
    </div>

    <div class="d-flex justify-center " style="height: 100%; padding-top: 70px">
      <v-card style="background: transparent; width: 90vw; min-height: 500px">
        <OrdnerverwaltungComponent v-if="menuItems[0].component"></OrdnerverwaltungComponent>
        <NutzerverwaltungComponent v-if="menuItems[1].component"></NutzerverwaltungComponent>
        <FilesverwaltungComponent v-if="menuItems[2].component"></FilesverwaltungComponent>
      </v-card>
    </div>
  </div>

  <div class="hidden-md-and-up" style="height: 40vh; width: 100vw">
    <div class="align-center d-flex justify-center mt-10">
      <v-alert class="text-center" color="red" style="max-width: 80vw; border-radius: 20px">
        <h1 class="text-center">Achtung</h1>
        Diese Ansicht ist auf dem Handy und auf dem Tablet nicht verfügbar. Bitte
        öffnen Sie diese auf Ihrem Laptop oder PC erneut
      </v-alert>
    </div>
  </div>
</template>-->

<script>

import OrdnerverwaltungComponent from "@/components/OrdnerverwaltungComponent";
import FilesverwaltungComponent from "@/components/FilesverwaltungComponent";
import NutzerverwaltungComponent from "@/components/NutzerverwaltungComponent";
import {mapGetters} from "vuex";
import router from "@/router";

export default {
  computed: {
    ...mapGetters(['user'])
  },
  created() {
    this.umleitung()
    this.setStatus()
  },
  mounted() {
    this.umleitung()
    this.$store.state.themeColor = '#21818d'
  },
  name: "VerwaltungsView",
  data() {
    return {
      appTitle: 'Verwaltung',
      sidebar: false,
      user1: '',
      tab: null,
      menuItems: [
        {title: 'Ordner', component: false, icon: 'material-symbols:folder'},
        {title: 'Nutzer', component: false, icon: 'mdi:user'},
        {title: 'Dateien', component: true, icon: 'ph:files-bold'},
      ],
    }
  },
  methods: {
    async umleitung() {
      console.log(this.user.status == 'Admin')
      if (!this.user) {
        this.$router.push('/')
      }
    },
    setStatus() {
      try {
        this.user1 = this.user.status
      } catch (e) {
        router.push('/')
      }
    },
    componentSwitchen(titel) {
      this.menuItems.forEach(function (e) {
        if (e.title === titel) {
          e.component = true
        } else {
          e.component = false
        }
      })
    }
  },
  components: {
    /*Icon,*/
    OrdnerverwaltungComponent,
    NutzerverwaltungComponent,
    FilesverwaltungComponent,
  },
};
</script>

<style scoped>

.hintergrund {
  background: linear-gradient(45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.card {
  background: transparent;
  border: 5px solid black;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
</style>
