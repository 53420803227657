<template>
  <v-lazy transition="slide-y-reverse-transition">
    <div class="hintergrund">
      <div class="d-flex align-center justify-center pt-10 pb-10"
           style="min-height: 88vh;width: 100vw; background-color: rgba(0,0,0,0.74)">
        <v-card style=" background-color: rgba(101,100,100,0.65); max-width: 2000px" theme="dark"
                width="90vw">
          <v-tabs v-model="tab"
                  :style="`background-color: ${projektColor}`"
                  center-active
                  class="tabs">
            <v-tab class="tab" value="one" @click="setColor('#21818d')">Portfolio</v-tab>
            <v-tab class="tab" value="two" @click="setColor('lightskyblue')">Brickau.de</v-tab>
            <v-tab class="tab" value="three" @click="setColor('aqua')">MyWatchlist</v-tab>
            <v-tab class="tab" value="four" @click="setColor('#2F53A7')">BG-Werl</v-tab>
            <v-tab class="tab" value="five" @click="setColor('#21818d')">Next project</v-tab>
          </v-tabs>
          <v-card-text style="min-height: 50vh">
            <v-window v-model="tab">
              <v-window-item value="one">
                <PortfolioComponent/>
              </v-window-item>
              <v-window-item value="two">
                <BrickauComponent/>
              </v-window-item>
              <v-window-item value="three">
                <MyWatchlistComponent/>
              </v-window-item>
              <v-window-item value="four">
                <BGWerlComponent/>
              </v-window-item>
              <v-window-item value="five">
                <div class="d-flex justify-center align-center" style="width: 100%; height: 45vh">
                  <div>
                    <h1 class="text-center mt-5 pb-5" style="color:black;"> ....in progress...</h1>
                    <Icon icon="svg-spinners:clock" style="font-size: 250px; color: #21818d"/>
                  </div>
                </div>
              </v-window-item>
            </v-window>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </v-lazy>
</template>

<script>
import MyWatchlistComponent from "@/components/projekte/MyWatchlistComponent";
import BrickauComponent from "@/components/projekte/BrickauComponent";
import PortfolioComponent from "@/components/projekte/PortfolioComponent";
import {Icon} from "@iconify/vue";
import BGWerlComponent from "@/components/projekte/BGWerlComponent";

export default {
  name: "ProjekteView",
  components: {BGWerlComponent, MyWatchlistComponent, BrickauComponent, PortfolioComponent, Icon},
  data: () => ({
    tab: '',
    projektColor: '#21818d',
  }),
  methods: {
    setColor(color) {
      this.projektColor = color
      this.$store.state.themeColor = color
    }
  }
}
</script>

<style scoped>
.tab {
  color: black;
  transition: 1s;
}

.hintergrund {
  background-image: url('../assets/projekte.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
</style>