<template>
  <h1 class="DesktopAnsicht hidden-sm-and-down text-center überschrift mt-5" style="font-size: 50px; height: 50px">
    Portfolio
    Website</h1>
  <h1 class="tabletAnsicht hidden-md-and-up text-center überschrift mt-5" style="font-size: 30px; height: 70px">
    Portfolio <br> <br> Website
  </h1>
  <v-row class="d-flex justify-center ml-0 mx-0 my-0 ma-0" style="width: 100%;">
    <v-col class="hidden-xs" cols="8" lg="6" md="9" sm="10">
      <v-carousel :interval="4000"
                  cycle
                  height="400"
                  hide-delimiter-background
                  hide-delimiters
                  show-arrows="hover">
        <v-carousel-item
            v-for="bild in bilder"
            :key="bild"
            :src="bild.img"
            @click="weiterleiten(bild.path)"
        ></v-carousel-item>
      </v-carousel>
    </v-col>
    <v-col class="d-flex align-center" cols="12" lg="6" md="9" sm="10">
      <v-expansion-panels
          v-model="panel">
        <v-expansion-panel
            v-for="text in texte"
            :key="text"
            class="text-white"
            style="background-color: #21818d">
          <v-expansion-panel-title>
            <h4>{{ text.titel }}</h4>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <p class="mx-2">{{ text.inhalt }}</p>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <v-col class="d-flex justify-center mt-5 pt-10" cols="12">
      <v-btn class="button" @click="weiterleiten('/')">
        <p style="font-max-size: 20px !important;"><b>Besuchen Sie Leandro-Graf.de</b></p>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "PortfolioComponent",
  data() {
    return {
      panel: [0],
      onboarding: 0,
      length: '',

      bilder: [
        {
          img: require('../../assets/ProjektBilder/PortfolioHome.png'),
          path: '/'
        },
        {
          img: require('../../assets/ProjektBilder/PortfolioAboutMe.png'),
          path: '/'
        },
        {
          img: require('../../assets/ProjektBilder/PortfolioProjekte.png'),
          path: '/Projekte'
        },
        {
          img: require('../../assets/ProjektBilder/PortfolioDateien.png'),
          path: '/files'
        },
        {
          img: require('../../assets/ProjektBilder/PortfolioVerwaltung.png'),
          path: '/'
        }
      ],

      texte: [
        {
          titel: 'Entstehung meiner eigenen Portfolio-Website',
          inhalt: 'Nach erfolgreicher Fertigstellung meiner vorherigen Projekte entschied ich mich, meine eigene Portfolio-Website zu erstellen, um potenziellen Kunden meine Arbeit vorzustellen und eine direkte Kontaktaufnahme zu ermöglichen.'
        },
        {
          titel: 'Vorstellung meiner Portfolio-Website',
          inhalt: 'Meine Portfolio-Website präsentiert meine IT-Kenntnisse, Projekte und Kontaktdaten. Hier können Interessierte einen Einblick in meine Fähigkeiten und Erfahrungen gewinnen. Neben persönlichen Informationen und Kontaktmöglichkeiten gibt es eine Übersicht meiner Projekte, wie z.B. MyWatchlist und Brickau.de.'
        },
        {
          titel: 'Kontaktinformationen und persönliche Details',
          inhalt: 'Auf meiner Website sind alle relevanten Kontaktinformationen, wie E-Mail-Adresse und Telefonnummer, zu finden. Potenzielle Kunden können sich direkt mit mir in Verbindung setzen und weitere Informationen über meine Arbeit erhalten.'
        },
        {
          titel: 'Vorstellung vergangener Projekte',
          inhalt: 'Ein Schwerpunkt meiner dieser Website liegt auf der Präsentation meiner vergangenen Projekte, wie z.B. MyWatchlist, eine personalisierte Filmliste mit Bewertungsfunktion. Außerdem zeige ich Brickau.de, eine von mir entwickelte Portfolio-Website für Professor Dr. Prof. Ralf A. Brickau. Diese Projekte demonstrieren meine Webentwicklungsfähigkeiten und meine Fähigkeit, maßgeschneiderte Lösungen zu erstellen.'
        },
        {
          titel: 'Weiterentwicklung der Cloud-Filesharing-Lösung',
          inhalt: 'Ein weiteres Highlight ist die Weiterentwicklung meiner eigenen Cloud-Filesharing-Lösung. Ursprünglich für Professor Brickau entwickelt, habe ich die Lösung weiterentwickelt und an meine eigenen Bedürfnisse angepasst. Diese Cloud ermöglicht Benutzern das sichere Hochladen, Teilen und Verwalten von Dateien. Sie stellt einen weiteren Meilenstein in meiner Entwicklung als IT-Student dar und zeigt meine Fähigkeiten in der Anpassung und Erweiterung von bestehenden Lösungen.'
        }
      ]
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkMobileView); // Event-Listener entfernen, um Speicherlecks zu vermeiden
  },
  methods: {
    checkMobileView() {
      if (window.innerWidth <= 768) {
        this.panel = '';
      } else {
        this.panel = [0];
      }
    },
    weiterleiten(path) {
      window.location.assign('https://leandro-graf.de' + path)
    },
  },
  mounted() {
    this.length = this.bilder.length
    this.checkMobileView();
    window.addEventListener('resize', this.checkMobileView);
  },
}
</script>

<style scoped>
.button {
  background-color: #21818d;
}

.button:hover {
  color: white !important;
  background-color: black !important;
  font-size: 15px;
}

.v-expansion-panel {
  max-width: 700px;
}

.überschrift {
  color: #21818d;
  text-shadow: 1px 0px white;
}
</style>