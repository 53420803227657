<template>
  <v-app-bar :style="{ backgroundColor: themeColor, ...boxShadowStyle }" class="header" fixed
             style="width: 100vw; ">
    <v-row style="width: 100%">
      <v-col class="d-flex justify-end align-center" cols="2">
        <Icon class="icon ml-5 button" icon="material-symbols:home" @click="$router.push('/')"/>
      </v-col>

      <v-col class="DesktopAnsicht d-flex align-center justify-center mt-8" cols="8"
             style="height: 70px">

        <h1 :class="{ 'fade-in-out': isAnimating }" class="ml-8 mb-5 hidden-sm-and-down text-shades-white überschrift"
            @click="$router.push('/')">
          {{ currentText }}</h1>

        <h1 :class="{ 'fade-in-out': isAnimating }" class="ml-8 mb-5 hidden-md-and-up text-shades-white"
            style="color: black !important; font-size: 6vw"
            @click="$router.push('/')">
          {{ currentText }}</h1>
      </v-col>

      <v-col class="d-flex justify-start align-center" cols="2">
        <v-img class="imprintIcon hidden-xs" src="../assets/imprintIcon.png"
               @click="$router.push('/impressum')"></v-img>
        <icon v-if="!user" class="icon mr-5 mt-3 hidden-sm-and-up" icon="oi:account-login"
              @click="loginDialog = !loginDialog"></icon>
        <icon v-if="user" class="icon mr-5 mt-3 hidden-sm-and-up" icon="oi:account-logout"
              @click="logout"></icon>
      </v-col>

    </v-row>
  </v-app-bar>

  <template class="login">
    <v-row justify="center">
      <v-dialog
          v-model="loginDialog"
          persistent
          width="512"
      >
        <v-form @submit.prevent="login">
          <v-card color="black" style="box-shadow: 0px 0px 50px 30px black">
            <v-card-title class="text-center">
              <span class="text-h5">Anmelden</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        v-model="email"
                        label="Nutzername"
                        required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        v-model="password"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPassword ? 'text' : 'password'"
                        label="Passwort"
                        required
                        @click:append="showPassword = !showPassword"
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="error !== ''" cols="12">
                    <v-alert v-if="error == 'AxiosError: Request failed with status code 401'" color="red">Der
                      Nutzername oder das Passwort ist falsch!
                    </v-alert>
                    <v-alert v-else class="text-center" color="red">Es bestehen
                      derzeit
                      Serverprobleme.
                      Bitte wenden Sie sich an den Administrator der Website, um das Problem zu beheben!
                    </v-alert>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
              <v-btn
                  v-if="(!loadAnzeige && (password == '' || email == '') || (password == '' && email == '') && error == '') || ((password == currentPassword && email == currentEmail) && error !== '')"
                  class="buttonClose"
                  style="color: red !important;"
                  variant="text"

                  @click="loginDialog = false; error = ''; email= ''; password = ''"
              >
                Close
              </v-btn>
              <v-btn
                  v-if="(!loadAnzeige && (password !== '' && email !== '' && error == '') || (!loadAnzeige && ((password !== currentPassword || email !== currentEmail) && error !== '') && (password !== '' && email !== ''))) && !loadAnzeige"
                  class="buttonLogin"
                  type="submit"
                  variant="text"
                  @click="login"
              >
                Login
              </v-btn>
              <Icon v-if="loadAnzeige" icon="line-md:loading-twotone-loop" style="font-size: 40px"/>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-row>
  </template>


</template>

<script>
import {mapGetters} from "vuex";
import {Icon} from '@iconify/vue';
import axios from "axios";

export default {
  name: "HeaderComponent",
  components: {
    Icon,
  },
  data() {
    return {
      loadAnzeige: false,
      showPassword: false,
      list: [
        {name: 'Home', icon: 'ic:baseline-home', path: '/'},
        {name: 'Impressum', icon: 'material-symbols:text-snippet', path: '/impressum'},
      ],
      loginDialog: false,
      drawer: false,
      email: '',
      password: '',
      currentEmail: '',
      currentPassword: '',
      error: '',
      textArray: ["Leandro Graf", "Dualer Student", "WebDeveloper"],
      currentIndex: 0,
      isAnimating: false,
      routerPath: this.$router.path, // Aktueller Pfad des Routers
    }
  },
  mounted() {
    setInterval(() => {
      this.isAnimating = true;
      setTimeout(() => {
        this.currentIndex =
            this.currentIndex === this.textArray.length - 1
                ? 0
                : this.currentIndex + 1;
        this.isAnimating = false;
      }, 500);
    }, 3000);
  },
  watch: {
    '$route.path': function (newPath) {
      this.routerPath = newPath;
    }
  },
  computed: {
    boxShadowStyle() {
      return this.routerPath === '/projekte' || this.routerPath === '/files' || this.routerPath === '/impressum'
          ? {boxShadow: '0px 0px 0px black'}
          : null;
    },
    ...mapGetters(['user']),
    ...mapGetters(['themeColor']),
    currentText() {
      return this.textArray[this.currentIndex];
    },
    closeButtonCheck: function () {
      return !!(this.password === this.currentPassword & this.email === this.currentEmail);
    },
  },
  methods: {
    async logout() {
      localStorage.removeItem('token');
      this.$store.dispatch('user', null);
      await this.$router.push('/');
      await location.reload()
    },
    async login() {
      try {
        this.currentPassword = this.password
        this.currentEmail = this.email
        this.loadAnzeige = true;
        const response = await axios.post('https://leandro-graf.de:8080/auth/login', {
          email: this.email,
          password: this.password
        });
        localStorage.setItem('token', response.data)
        await this.$store.dispatch('user', response.data.user)
        this.loadAnzeige = false;

        await this.$router.push('/files');
        await location.reload()

      } catch (e) {
        this.loadAnzeige = false;
        this.error = e;
        console.log(e)
      }
    },
  }
}
</script>

<style scoped>
.header {
  height: 4.5rem;
  width: 100%;
}

.icon {
  font-size: 40px !important;
  color: black;
}

.überschrift {
  transition: 0.5s;
  color: black !important;
  font-size: 40px !important;
}

.überschrift:hover {
  overflow: visible;
  transition: 0.5s;
  font-size: 45px;
}

.fade-in-out {
  animation-name: fade-in-out;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
}

@keyframes fade-in-out {
  0% {
    opacity: 1;
    transition: 1s;
  }
  50% {
    transition: 1s;
  }
  75% {
    transition: 1s;
  }
  100% {
    transition: 1s;
    opacity: 0;
  }
}

.button {
  font-size: 40px;
  color: black !important;
  transition: 1.5s;
  background-color: transparent;
}

.button:hover {
  font-size: 50px;
  background-color: transparent !important;
  transition: 0.5s;
}

.buttonLogin {
  color: greenyellow;
  background-color: black;
  transition: 1.5s;
}

.buttonLogin:hover {
  color: black;
  transition: 0.5s;
  background-color: white !important;
}

.buttonClose {
  color: red;
  background-color: black;
  transition: 1.5s;
}

.buttonClose:hover {
  color: black !important;
  transition: 0.5s;
  background-color: white !important;
}

.imprintIcon {
  height: 35px;
  transition: 1.5s;
}

.imprintIcon:hover {
  transition: 0.5s;
  height: 45px !important;
  cursor: pointer;
}

</style>
