<template>
  <v-lazy transition="fab-transition">
    <div class="d-flex justify-center mb-5" style="width: 100vw;">
      <v-row class="mt-5" style="width: 100%;">
        <v-col v-for="selected in projekte" :key="selected" class="d-flex justify-center" cols="12">
          <v-card
              class=" align-center text-center ma-2"
              elevation="2"
              style="width: 90vw; background-color: rgba(101,100,100,0.65)"
          >
            <v-card-title>
              <h1
                  class="text-h2 text-white"
              >
                <h4 class="text-beige">{{ selected.name }}</h4>
              </h1>
            </v-card-title>
            <v-card-item class="d-flex justify-center mt-3">
              <v-row style="width: 100%">
                <v-col class="image" cols="6">
                  <v-img :src="selected.img1" aspect-ratio="16/9" class="image"
                         style="max-height: 300px;width: 500px"
                         @click="weiterleiten(selected.url)"/>
                </v-col>
                <v-col class="image" cols="6">
                  <v-img :src="selected.img2" style="max-height: 300px;width: 500px"
                         @click="weiterleiten(selected.img2url)"/>
                </v-col>
              </v-row>
            </v-card-item>
            <v-card-item style="margin: 0px 150px 0px 150px" class="d-flex justify-center">
              <v-lazy transition="slide-y-reverse-transition">
                <v-expansion-panels class="my-4" theme="dark" variant="popout">
                  <v-expansion-panel
                      v-if="selected.inhalt1!=''"
                      :text="selected.inhalt1"
                      :title="selected.überschrift1"
                  ></v-expansion-panel>
                  <v-expansion-panel
                      v-if="selected.inhalt2!=''"
                      :text="selected.inhalt2"
                      :title="selected.überschrift2"
                  ></v-expansion-panel>
                  <v-expansion-panel
                      v-if="selected.inhalt3!=''"
                      :text="selected.inhalt3"
                      :title="selected.überschrift3"
                  ></v-expansion-panel>
                  <v-expansion-panel
                      v-if="selected.inhalt4!=''"
                      :text="selected.inhalt4"
                      :title="selected.überschrift4"
                  ></v-expansion-panel>
                  <v-expansion-panel
                      v-if="selected.inhalt5!=''"
                      :text="selected.inhalt5"
                      :title="selected.überschrift5"
                  ></v-expansion-panel>
                  <v-expansion-panel
                      v-if="selected.inhalt6!=''"
                      :text="selected.inhalt6"
                      :title="selected.überschrift6"
                  ></v-expansion-panel>
                  <v-expansion-panel></v-expansion-panel>
                </v-expansion-panels>
                <!--              <b class="text-white"> {{ selected.inhalt }}</b>-->
              </v-lazy>
            </v-card-item>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-lazy>
</template>

<script>
export default {
  name: "testView",
  data: () => ({
    projekte:
        [{
          name: 'MyWatchlist',
          img1: require('../assets/ProjektBilder/MyWatchlistHome.jpg'),
          img2: require('../assets/ProjektBilder/MyWatchlistFilme.png'),
          url: "https://leandro-graf.de/MyWatchlist",
          img2url: "https://leandro-graf.de/MyWatchlist/liste/",

          überschrift1: 'Wie ist das Projekt enstanden?',
          überschrift2: 'Wofür ist die Website gedacht?',
          überschrift3: 'Ihre eigene Sammlung an Filmen',
          überschrift4: 'Haben Sie alles gefunden?',
          überschrift5: ' ',
          überschrift6: ' ',
          inhalt1: 'Die Website leandro-graf.de/MyWatchlist ist eine persönliche Website, die von Leandro Graf erstellt wurde und sich auf das Thema "Watchlist" oder "Beobachtungsliste" konzentriert. Die Website bietet Benutzern die Möglichkeit, ihre persönliche Watchlist zu erstellen und zu verwalten, um Filme, TV-Shows oder andere Medieninhalte im Auge zu behalten.',
          inhalt2: 'Der Inhalt der Website besteht aus verschiedenen Funktionen und Abschnitten, die es den Benutzern ermöglichen, ihre Watchlist nach ihren Vorlieben zu gestalten. Die Hauptfunktion der Website ist die Möglichkeit, Medieninhalte zur Watchlist hinzuzufügen. Benutzer können Filme, TV-Shows oder andere Medien suchen und diese zu ihrer persönlichen Liste hinzufügen. Sie können auch eine Bewertung oder Kommentare zu den Inhalten hinterlassen, um ihre Meinungen und Eindrücke zu teilen.',
          inhalt3: 'Ein weiterer Abschnitt der Website ist der Bereich "Meine Watchlist", in dem Benutzer ihre persönliche Watchlist verwalten können. Sie können Inhalte aus ihrer Watchlist entfernen, ihre Bewertungen oder Kommentare bearbeiten oder die Inhalte in verschiedenen Kategorien organisieren, um sie leichter wiederzufinden. Es gibt auch Optionen, um die Watchlist zu teilen oder herunterzuladen, um sie auf anderen Geräten oder Plattformen zu nutzen.',
          inhalt4: 'Die Website leandro-graf.de/MyWatchlist bietet auch eine Suchfunktion, mit der Benutzer nach bestimmten Medieninhalten suchen können, um sie zu ihrer Watchlist hinzuzufügen. Es gibt auch eine Kategorie mit beliebten Medieninhalten, die von anderen Benutzern empfohlen werden. Darüber hinaus bietet die Website auch Neuigkeiten und Updates zu neuen Filmen, TV-Shows oder anderen Medieninhalten.',
          inhalt5: '',
          inhalt6: '',
        }, {
          name: 'Portfolio Brickau',
          img1: require('../assets/ProjektBilder/BrickauHome.png'),
          img2: require('../assets/ProjektBilder/BrickauFiles.png'),
          url: "https://leandro-graf.de/Brickau",
          img2url: "https://leandro-graf.de/Brickau/files",
          überschrift1: 'Wie ist das Projekt enstanden?',
          überschrift2: 'Wie ist die Idee entstanden?',
          überschrift3: 'Wie fing das Projekt an?',
          überschrift4: '',
          überschrift5: ' ',
          überschrift6: ' ',
          inhalt1: 'Als leidenschaftlicher Webentwickler und engagierter Student habe ich vor kurzem eine Website für meinen Professor erstellt, die ein Verwaltungssystem und eine Portfolio-Seite beinhaltet. Diese Erfahrung war spannend und lehrreich, da sie es mir ermöglichte, meine technischen Fähigkeiten zu nutzen und meiner kreativen Seite freien Lauf zu lassen.',
          inhalt2: 'Die Idee, eine Website für meinen Professor zu erstellen, entstand aus der Notwendigkeit, seine Kurse und Projekte effizient zu verwalten. Ein Verwaltungssystem war eine praktische Lösung, um die Organisation von Kursinhalten, Benutzerregistrierungen, Prüfungen und Benutzerdaten zu erleichtern. Es war eine anspruchsvolle Herausforderung, die ich jedoch mit Begeisterung angenommen habe.',
          inhalt3: 'Der erste Schritt war die Auswahl der richtigen Technologien und Frameworks für das Projekt. Nach einer gründlichen Recherche entschied ich mich für eine Kombination aus PHP für die Backend-Entwicklung und HTML, CSS und JavaScript für die Frontend-Entwicklung. Als Datenbank wählte ich MySQL, da es zuverlässig und skalierbar ist.',
          inhalt4: '',
          inhalt5: '',
          inhalt6: '',
        }]
  }),
  methods: {
    weiterleiten(url) {
      window.location = url;
    },
  }
}
</script>

<style scoped>
.image {
  overflow: hidden;
  display: grid;
  transition: transform 0.5s;
}

.image:hover {
  transform: scale(0.98);
  transition: transform 0.5s;
}

.v-expansion-panels {
  max-width: 1000px;
}
</style>