<template>
  <div class="hintergrundbild">
    <div class="d-flex align-center justify-center"
         style="min-height: 88vh;width: 100vw; background-color: rgba(0,0,0,0.74)">
      <div class="text-white mt-8 pb-8" style="margin-left: 2%; width: 90vw">
        <h2>Impressum</h2>
        <p>Leandro Graf<br/>
          Dualer Student am IT-Center Dortmund<br/>Otto-Hahn-Straße 19<br/>44227 Dortmund</p>
        <!--        Telefon: <a class="email" href="tel:+491635059366">+49 163 5059366</a> <br>-->
        E-Mail: <a class="email" href="mailto:lelegraf1503@gmail.com">lelegraf1503@gmail.com</a> <br>
        E-Mail: <a class="email" href="mailto:leandro.graf@itc-studenten.de">leandro.graf@itc-studenten.de</a> <br>
        E-Mail: <a class="email" href="mailto:leandro.graf@quinscape.de">leandro.graf@quinscape.de</a>
        <br><br>
        <h2>Haftung für Inhalte:</h2>
        <p>
          Als Seitenbetreiber bin ich für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich.
          Ich
          bin jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach
          Umständen
          zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
          Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung
          ist
          jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von
          entsprechenden Rechtsverletzungen werde ich diese Inhalte umgehend entfernen.</p> <br>
        <h2>Haftung für Links:</h2>
        <p>Diese Website enthält Links zu externen Websites Dritter, auf deren Inhalte ich keinen Einfluss habe. Deshalb
          kann ich für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist
          stets
          der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt
          der
          Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung
          nicht
          erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte
          einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werde ich derartige Links
          umgehend
          entfernen.</p> <br>
        <h2>Urheberrecht:</h2>
        <p>Die durch mich erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die
          Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des
          Urheberrechtes
          bedürfen meiner schriftlichen Zustimmung. Downloads und Kopien dieser Seite sind nur für den privaten, nicht
          kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht von mir erstellt wurden, werden
          die
          Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
          trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitte ich um einen entsprechenden Hinweis. Bei
          Bekanntwerden von Rechtsverletzungen werde ich derartige Inhalte umgehend entfernen.</p>

      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "ImpressumView"
  ,
  components: {},
  mounted() {
    this.$store.state.themeColor = '#21818d'
  }
}
</script>

<style>
.email {
  color: beige;
  transition: 0.5s;
}

.email:hover {
  transition: 0.5s;
  font-size: 17px;
}

.hintergrundbild {
  background-image: url('../assets/impressum.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
</style>